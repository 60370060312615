import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router';
import './App.css';
import Navbar from './Components/navbar';
import Home from './Pages/home';
import AddQuestions from './Pages/add-questions';
import AddQuestionsNew from './Pages/new-questions';

// Define the component for each page
const Contact = () => <div><h2>Contact Page</h2><p>Contact us through this page.</p></div>;

// App component with navbar and routing
function App() {
  return (
    <Router>
      <div className='flex flex-col w-screen h-screen'>
        {/* Navbar Component */}
        <Navbar />

        {/* Routes for different pages */}
        <div className="content flex-1 overflow-y-auto">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/addQuestions" element={<AddQuestions />} />
            <Route path="/addQuestionsNew" element={<AddQuestionsNew />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
