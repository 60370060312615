import React from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

const MathJaxComponent = ({ latex }) => {

  return (
    <MathJaxContext>
      <div>
        <MathJax>{latex}</MathJax>
      </div>
    </MathJaxContext>
  );
};

export default MathJaxComponent;
