import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';

const FilterBar = ({ classes, subjects, chapters, onClassSelect, onSubjectSelect, onChapterSelect }) => {

  const [selectedClassId, setSelectedClassId] = useState();
  const [selectedSubjectId, setSelectedSubjectId] = useState();
  const [selectedChapterId, setSelectedChapterId] = useState();

  const handleOnClassSelect = (value) => {
    onClassSelect(value)
    setSelectedClassId(value);
  }

  const handleOnSubjectSelect = (value) => {
    onSubjectSelect(value)
    setSelectedSubjectId(value);
  }

  const handleOnChapterSelect = (value) => {
    onChapterSelect(value)
    setSelectedChapterId(value);
  }

  return (
    <Box display="flex" gap={2}>

      <FormControl variant="outlined" sx={{ minWidth: 150 }}>
        <InputLabel id="select1-label">Class</InputLabel>
        <Select
          labelId="select1-label"
          onChange={(e) => handleOnClassSelect(e.target.value)}
          label="Select 1"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          {
            classes && classes.map((v, i) => <MenuItem key={`${i}`} value={v._id}>{v.name}</MenuItem>)
          }

        </Select>

        <p>{selectedClassId}</p>
      </FormControl>

      <FormControl variant="outlined" sx={{ minWidth: 150 }}>
        <InputLabel id="select2-label">Subject</InputLabel>
        <Select
          labelId="select2-label"
          onChange={(e) => handleOnSubjectSelect(e.target.value)}
          label="Select 2"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          {
            subjects && subjects.map((v, i) => <MenuItem key={`${i}`} value={v._id}>{v.name}</MenuItem>)
          }


        </Select>
        <p>{selectedSubjectId}</p>
      </FormControl>

      <FormControl variant="outlined" sx={{ minWidth: 200 }}>
        <InputLabel id="select3-label">Chapter</InputLabel>
        <Select
          labelId="select3-label"
          onChange={(e) => handleOnChapterSelect(e.target.value)}
          label="Select 3"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          {
            chapters && chapters.map((v, i) => <MenuItem key={`${i}`} value={v._id}>{v.title}</MenuItem>)
          }

        </Select>
        <p>{selectedChapterId}</p>
      </FormControl>
    </Box>
  );
};

export default FilterBar;
