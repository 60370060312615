import React from 'react';
import QuestionItem from './question-item';
import { Box } from '@mui/material';

const QuestionList = ({questions, page}) => {
    return (
        <Box>
            {   
                questions.map((v, i) => <QuestionItem key={v._id} page={page} index={i+1} question={v} />)
            }
        </Box>
    )
}

export default QuestionList;