import { NavLink } from 'react-router';

const Navbar = () => {
    return (
        <nav className="bg-blue-500 p-4 shadow-md">
            <ul className="flex space-x-6 justify-center">
                <li>
                    <NavLink
                        className={({ isActive }) =>
                            `navlink text-white hover:text-yellow-400 transition-all ${isActive ? 'text-yellow-500 font-bold' : ''}`}
                        to="/"
                        end
                    >
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={({ isActive }) =>
                            `navlink text-white hover:text-yellow-400 transition-all ${isActive ? 'text-yellow-500 font-bold' : ''}`}
                        to="/addQuestions"
                    >
                        Add Questions
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={({ isActive }) =>
                            `navlink text-white hover:text-yellow-400 transition-all ${isActive ? 'text-yellow-500 font-bold' : ''}`}
                        to="/addQuestionsNew"
                    >
                        New Questions
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={({ isActive }) =>
                            `navlink text-white hover:text-yellow-400 transition-all ${isActive ? 'text-yellow-500 font-bold' : ''}`}
                        to="/contact"
                    >
                        Contact
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;