import axios from "axios";

const API_URL = "https://www.vgyani.com/api/questionValidation/";
const ADD_API_URL = "https://www.vgyani.com/api/question/storeNewQuestions";

export function GetClasses(callback) {
    axios.get(API_URL + "class").then(data => {
        callback(data.data);
    }).catch(err => console.log(err))
}

export function GetSubjects(callback) {
    axios.get(API_URL + "subject").then(data => {
        callback(data.data);
    }).catch(err => console.log(err))
}

export function GetChapters(classId, subjectId, callback) {
    axios.get(API_URL + "chapter", { params: { classId, subjectId } }).then(data => {
        callback(data.data);
    }).catch(err => console.log(err))
}

export function GetTopics(chapterId, callback) {
    axios.get(API_URL + "topic", { params: { chapterId } }).then(data => {
        callback(data.data);
    }).catch(err => console.log(err))
}

export function GetQuestions(chapterId, topicId, callback) {
    axios.get(API_URL + "question", { params: { chapterId, topicId } }).then(data => {
        callback(data.data);
    }).catch(err => console.log(err))
}

export function validateStatus(questionId, value, callback) {
    axios.get(API_URL + "validate", { params: { questionId, value } }).then(data => {
        callback(data.data);
    }).catch(err => console.log(err))
}

export function updateTopicStatus(topicId, value, callback) {
    axios.get(API_URL + "updateTopic", { params: { topicId, value } }).then(data => {
        callback(data.data);
    }).catch(err => console.log(err))
}

export function addNewQuestions(questionsArray, callback) {
    axios.get(ADD_API_URL, { params: {data: questionsArray} }).then(data => {
        callback(false, data.data);
    }).catch(err => {
        callback(err);
    })
}