import React from 'react';

const NewQuestions = () => {


    return (
        <div className='flex h-full overflow-hidden'>
            <iframe
                src="/WebGL/index.html"
                width="100%"
                height="600px"
                style={{ border: "none" }}
                allowFullScreen
            />
        </div>
    );
};

export default NewQuestions;