import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MathJaxComponent from './mathjax';
import { validateStatus } from '../Utils/server';
import { Button, Divider, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const QuestionItem = ({ index, question, page }) => {

    const [status, setStatus] = useState(question.validated);

    const url = "http://localhost:3030/images";

    const validate = (value) => {
        const questionId = question._id;
        validateStatus(questionId, value, (data) => {
            if (data.status) {
                setStatus(value);
            }
        })
    }

    const copyToClipboard = () => {
        const jsonContent = {
            title: question.title,
            option_a: question.answers[0].title,
            option_b: question.answers[1].title,
            option_c: question.answers[2].title,
            option_d: question.answers[3].title,
            solution: question.solution
        };
        const jsonString = JSON.stringify(jsonContent, null, 2);
        navigator.clipboard.writeText(jsonString)
            .then(() => {
                alert('JSON copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy!', err);
            });
    };

    const formatLatex = (latex) => {
        return latex.replace(/\$(.*?)\$/g, '\\($1\\)').replace(/\\\\/g, '\n');
    }

    return (
        <Paper elevation={3} sx={{ mb: 2, p: 2 }}>

            <Box display={'flex'} justifyContent={"right"}>
                <Button variant="outlined" onClick={copyToClipboard} startIcon={<ContentCopyIcon />} size='small'>
                    Copy JSON
                </Button>
            </Box>
            <Typography variant="h6">Question {index + (page) * 10}:</Typography>
            <Typography variant="h6body1">
                <MathJaxComponent latex={formatLatex(question?.title)} />
            </Typography>

            <Box display="flex" justifyContent="center">
                {
                    question.title_images.length > 0 && question.title_images.map((v, i) => {
                        return <img
                            src={`${url}/${question.image_dir}/${v}`}
                            loading="lazy"
                        />
                    })
                }
            </Box>

            {
                question.answers && question.answers.map((v, i) => {
                    return (
                        <Box sx={{ mb: 2, mt: 2 }}>

                            <Typography variant='h6'>Option {i == 0 ? "A)" : i == 1 ? "B)" : i == 2 ? "C)" : "D)"}</Typography>
                            <Typography variant='body1'>
                                <MathJaxComponent latex={formatLatex(v.title)} />
                            </Typography>

                            <Box display="flex" justifyContent="center">
                                {
                                    v.title_images.length > 0 && v.title_images.map((vi, i) => {
                                        return <img
                                            src={`${url}/${question.image_dir}/${vi}`}
                                            loading="lazy"
                                        />
                                    })
                                }
                            </Box>
                        </Box>

                    )
                })
            }

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Typography variant='h6' sx={{ mb: 2 }}>Solution</Typography>

            <Typography variant='body1'>
                <MathJaxComponent latex={formatLatex(question.solution)} />
            </Typography>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box display="flex" justifyContent="flex-end">
                <Button type='button' variant="contained" disabled={!status} sx={{ mr: 2 }} color="error" onClick={() => validate(false)}>Un-Verify</Button>
                <Button type='button' variant="contained" disabled={status} onClick={() => validate(true)}>Verify</Button>
            </Box>
        </Paper>
    )
}

export default QuestionItem;