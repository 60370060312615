import React, { useEffect, useState, useRef } from 'react';
import { Box, Container } from '@mui/material';
import FilterBar from '../Components/filter-bar';
import TopicList from '../Components/topic-list';
import { GetClasses, GetSubjects, GetChapters, GetTopics, GetQuestions, updateTopicStatus } from '../Utils/server';
import QuestionList from '../Components/question-list';
import TablePagination from '@mui/material/TablePagination';

const Home = () => {
    const [page, setPage] = useState(0);
    const [topics, setTopics] = useState([]);
    const [classes, setClasses] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [selectedClass, setSelectedClass] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState([]);
    const [selectedChapter, setSelectedChapter] = useState([]);

    const rowsPerPage = 10;

    useEffect(() => {
        GetClasses((data) => {
            setClasses(data.data);
        });

        GetSubjects((data) => {
            setSubjects(data.data);
        });
    }, []);

    useEffect(() => {
        setCurrentData(questions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }, [page])

    useEffect(() => {
        setCurrentData(questions.slice(0, rowsPerPage));
    }, [questions])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const onClassSelect = (classId) => {
        setSelectedClass(classId);
        resetView();
    }

    const onSubjectSelect = (subjectId) => {
        setSelectedSubject(subjectId);

        GetChapters(selectedClass, subjectId, (data) => {
            setChapters(data.data);
        });
        resetView();
    }

    const onChapterSelect = (chapterId) => {
        setSelectedChapter(chapterId);

        GetTopics(chapterId, (data) => {
            setTopics(data.data);
        })

        setQuestions([]);
    }

    const onTopicSelect = (topicId) => {
        setSelectedTopic(topicId);

        GetQuestions(selectedChapter, topicId, (data) => {
            setQuestions(data.data);
            setPage(0);
        })
    }

    const changeTopicStatus = (topicId, status) => {
        const arr = topics.map(v => {
            if (v._id == topicId) v.status = status;
            return v;
        })
        setTopics(arr);
    }

    const resetView = () => {
        setQuestions([]);
        setTopics([]);
    }

    return (
        <div className="w-full mx-auto p-4 overflow-hidden">
            <div className="flex flex-col h-full">
                <div className="flex pt-2 pb-2">
                    <div className="w-3/5">
                        <FilterBar
                            classes={classes}
                            onClassSelect={onClassSelect}
                            subjects={subjects}
                            onSubjectSelect={onSubjectSelect}
                            chapters={chapters}
                            onChapterSelect={onChapterSelect}
                        />
                    </div>

                    <div className="w-2/5">
                        {questions.length > 0 && (
                            <TablePagination
                                component="div"
                                count={questions.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                labelRowsPerPage=""
                                rowsPerPageOptions={[]}
                            />
                        )}
                    </div>
                </div>

                <div className="flex flex-1">
                    <div className="w-1/4 overflow-auto border-r border-gray-300 p-2">
                        <TopicList
                            topics={topics}
                            selectedTopic={selectedTopic}
                            onTopicSelect={onTopicSelect}
                            changeTopicStatus={changeTopicStatus}
                        />
                    </div>

                    <div className="w-3/4 overflow-auto p-2">
                        <p className='p-2 bg-red-300'>Selected Topic ID: {selectedTopic}</p>
                        <QuestionList questions={currentData} page={page} />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Home;