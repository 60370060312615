import React, { useState } from 'react';
import { addNewQuestions } from '../Utils/server';

const AddQuestions = () => {

    const [jsonInput, setJsonInput] = useState('');
    const [status, setStatus] = useState('');

    const handleInputChange = (event) => {
        setJsonInput(event.target.value);
    };

    const handleClearResult = () => {
        setStatus('');
    };

    const handleAddQuestions = () => {

        if(!validateJson()) return;

        addNewQuestions(jsonInput, (err, data) => {
            if (err) {
                setStatus({ error: true, message: err.message });
                return;
            }

            setStatus({ error: false, message: getMessageContent(data) });
        })
    };

    const getMessageContent = (data) => {
        return (
            <div>
                <p>Message: {data.message}</p>
                <p>Successfully Saved Count: {data.savedQuestions.length}</p>
                <p className='text-red-600'>Unsuccessful Count: {data.unsavedQuestions.length}</p>
                <hr />
                <pre className='text-red-600'>
                    {JSON.stringify(data.unsavedQuestions, null, 2)}
                </pre>
            </div>
        )
    }

    const validateJson = () => {
        try {
            JSON.parse(jsonInput);
            setStatus({
                error: false,
                message: 'Valid JSON!'
            });

            return true;
        } catch (e) {
            setStatus({
                error: true,
                message: 'Invalid JSON!'
            });
        }

        return false;
    };

    return (
        <div className='flex h-full p-8 space-x-4'>
            <div className='w-3/5 flex flex-col h-full space-y-4'>
                <div className='flex-1'>
                    <textarea
                        className='h-full w-full bg-gray-100 p-2'
                        value={jsonInput}
                        onChange={handleInputChange}></textarea>
                </div>
                <div className='text-right'>
                    <button onClick={validateJson} className='py-2 px-4 bg-green-500 mr-4'>Validate JSON</button>
                    <button onClick={handleAddQuestions} className='py-2 px-4 bg-blue-500'>Add Questions</button>
                </div>
            </div>

            <div className='w-2/5 flex flex-col space-y-4 h-full'>
                <div className='bg-yellow-50 px-2 py-2 flex'>
                    <p className='flex-1'>
                        Result
                    </p>

                    <button onClick={handleClearResult} className='px-4 bg-red-300'>Clear Output</button>
                </div>

                <div className='flex-1 bg-yellow-50 p-2 overflow-y-auto'>
                    {
                        status && (
                            <p className={status.error ? 'text-red-600' : 'text-green-600'}>
                                {status.message}
                            </p>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default AddQuestions;