import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { updateTopicStatus } from '../Utils/server';

const TopicList = ({ topics, selectedTopic, onTopicSelect, changeTopicStatus }) => {

    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: theme.transitions.create(['width'], {
                duration: 200,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
            boxSizing: 'border-box',
        },
    }));

    const onSwitch = (topicId, status) => {
        console.log(topicId, status);

        updateTopicStatus(topicId, status, (data) => {
            if (data.status) {
                changeTopicStatus(topicId, status);
            }
        });
    }

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <nav aria-label="main mailbox folders">
                <List>
                    {
                        topics && topics.map((v, i) => (
                            <ListItem disablePadding key={v._id} secondaryAction={
                                <AntSwitch
                                    defaultChecked={v.status}
                                    onChange={(e) => onSwitch(v._id, e.target.checked)}
                                    inputProps={{ 'aria-label': 'ant design' }} />
                            }
                            >
                                <ListItemButton
                                    onClick={() => onTopicSelect(v._id)}
                                    selected={selectedTopic == v._id} >
                                    <ListItemText primary={v.name} />
                                </ListItemButton>
                            </ListItem>
                        ))
                    }

                </List>
            </nav>

        </Box>
    );
}

export default TopicList;